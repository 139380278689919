<template>
  <v-container>
    <v-row>
      <v-card-title class="primary--text">Users control</v-card-title>
    </v-row>
    <v-card v-if="edit === 0">
      <v-col>
        <v-btn color="primary" @click="searchByName = 1; searchByRole = 0">Search by name</v-btn>
        <v-btn class="ml-3" color="primary" @click="searchByRole = 1; searchByName = 0">Search by Role</v-btn>
        <v-btn class="ml-3" color="primary" @click="sortByName()">Sort by Name</v-btn>
        <v-btn class="ml-3" color="primary" @click="sortByRole()">Sort by Role</v-btn>
        <v-text-field label="Enter name" v-if="searchByName === 1" v-model="searchByNameCriteria" @input="searchName()"
                      append-outer-icon="mdi-close"
                      @click:append-outer="searchByName = 0"></v-text-field>
        <v-select label="Select Role" class="mt-3" v-if="searchByRole === 1" outlined v-model="searchByRoleCriteria"
                  :items="roles" item-text="name" item-value="value" append-outer-icon="mdi-close"
                  @click:append-outer="searchByRole = 0" @change="searchRole()"></v-select>
      </v-col>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Filter current data"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="item.role.name !== 'Admin'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
              small
              class="mr-2"
              @click="editUser(item)"
              v-bind="attrs"
              v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit User Role</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.deleted_at === null && item.role.name !== 'Admin'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
              small
              @click="deletingItem = item; deleteDialog = true"
              v-bind="attrs"
              v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete User</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.deleted_at && item.role.name !== 'Admin'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
              small
              @click="restoreUser(item)"
              v-bind="attrs"
              v-on="on"
              >
                mdi-backup-restore
              </v-icon>
            </template>
            <span>Restore User</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-card v-else-if="edit === 1">
      <v-container>
        <v-text-field outlined v-model="editingUserName" readonly></v-text-field>
        <v-select outlined v-model="editingUserRole" :items="roles" item-text="name" item-value="value"></v-select>
        <v-btn color="primary" @click="editThisUser">Submit</v-btn>
        <v-btn class="ml-2" color="primary"
               @click="edit = 0; editingUserRole = null; baseEditingUserRole = null; editingUserName = null; editingUserId = null">
          Cancel
        </v-btn>
      </v-container>
    </v-card>

    <v-dialog v-model="deleteDialog">
      <v-card>
        <v-card-title class="primary--text">Are you sure you want to delete this user ?</v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text color="warning" @click="deleteDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn tile color="primary" elevation="0" @click="deleteUser(deletingItem)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    <v-simple-table class="table">-->
    <!--      <thead>-->
    <!--      <tr>-->
    <!--        <th class="primary&#45;&#45;text">-->
    <!--          ID-->
    <!--        </th>-->
    <!--        <th class="primary&#45;&#45;text">-->
    <!--          Name-->
    <!--        </th>-->
    <!--        <th class="primary&#45;&#45;text">-->
    <!--          Email-->
    <!--        </th>-->
    <!--        <th class="primary&#45;&#45;text">-->
    <!--          Nickname-->
    <!--        </th>-->
    <!--        <th class="primary&#45;&#45;text">-->
    <!--          Role-->
    <!--        </th>-->
    <!--        <th class="primary&#45;&#45;text">-->
    <!--          Actions-->
    <!--        </th>-->
    <!--      </tr>-->
    <!--      </thead>-->
    <!--      <tbody>-->
    <!--      <tr-->
    <!--        v-for="item in users"-->
    <!--        :key="item.id"-->
    <!--      >-->
    <!--        <td>{{ item.id }}</td>-->
    <!--        <td>{{ item.full_name }}</td>-->
    <!--        <td>{{ item.email }}</td>-->
    <!--        <td>{{ item.nick_name }}</td>-->
    <!--        <td>{{ roles.find(x => x.value === item.role_id).name}}</td>-->
    <!--        <td><v-btn text color="primary"><v-icon>mdi-pencil-outline</v-icon></v-btn> <v-btn text color="primary"><v-icon>mdi-trash-can</v-icon></v-btn></td>-->
    <!--      </tr>-->
    <!--      </tbody>-->
    <!--    </v-simple-table>-->
    <!--    <div class="text-center mt-5">-->
    <!--    <v-btn @click="previousPage" color="primary"><v-icon>mdi-arrow-left-thin</v-icon></v-btn>-->
    <!--    <v-btn @click="nextPage" class="ml-5" color="primary"><v-icon>mdi-arrow-right-thin</v-icon></v-btn>-->
    <!--    </div>-->

  </v-container>
</template>
<script>
import admin from '@/web_services/admin'

export default {
  data () {
    return {
      users: [],
      editingUserName: null,
      editingUserId: null,
      editingUserRole: null,
      baseEditingUserRole: null,
      currentPage: 0,
      searchByName: 0,
      searchByRole: 0,
      loading: false,
      searchByNameCriteria: null,
      searchByRoleCriteria: null,
      search: null,
      edit: 0,
      previousValue: 0,
      deletingItem: null,
      deleteDialog: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Name',
          value: 'full_name'
        },
        {
          text: 'E-mail',
          value: 'email'
        },
        {
          text: 'Nick name',
          value: 'nick_name'
        },
        {
          text: 'Role',
          value: 'role.name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      roles: [
        {
          value: 1,
          name: 'Admin'
        },
        {
          value: 2,
          name: 'Operator'
        },
        {
          value: 3,
          name: 'Agency'
        },
        {
          value: 4,
          name: 'Traveler'
        }
      ]
    }
  },
  created () {
    this.getResults()
  },
  mounted () {
  },

  methods: {
    // Our method to GET results from a Laravel endpoint
    getResults (page = 1) {
      this.loading = true
      this.currentPage = page
      admin.allUsers(page)
        .then(response => {
          if (response.data.data.length > 0) {
            this.users = response.data.data
          } else {
            this.currentPage = this.previousValue
          }
        }).finally(() => { this.loading = false })
    },
    nextPage () {
      this.previousValue = this.currentPage
      this.currentPage += 1
      this.getResults(this.currentPage)
    },
    previousPage () {
      if (this.currentPage > 1) {
        this.previousValue = this.currentPage
        this.currentPage -= 1
        this.getResults(this.currentPage)
      }
    },
    editUser (item) {
      this.editingUserName = item.full_name
      this.editingUserId = item.id
      this.editingUserRole = this.roles.find(x => x.name === item.role.name).value
      this.baseEditingUserRole = this.editingUserRole
      this.edit = 1
    },
    deleteUser (item) {
      admin.deleteUser(item.id).then(res => {
        this.users = res.data.data
        this.$emit('success', 'User deleted successfully')
      }).catch(() => {
        this.$emit('error', 'Error')
      }).finally(() => {
        this.deleteDialog = false
      })
    },
    restoreUser (item) {
      admin.restoreUser(item.id).then(res => {
        this.users = res.data.data
        this.$emit('success', 'User restored successfully')
      }).catch(() => {
        this.$emit('error', 'Error')
      }).finally(() => {
      })
    },
    editThisUser () {
      if (this.editingUserRole !== this.baseEditingUserRole) {
        const data = { role_id: this.editingUserRole }
        admin.editUser(data, this.editingUserId).then(res => {
          this.users = res.data.data
          this.$emit('success', 'User edited successfully')
        }).catch(error => {
          this.$emit('error', error)
        }).finally(() => {
          this.edit = 0
        })
      } else {
        this.edit = 0
        this.editingUserId = null
        this.editingUserRole = null
        this.editingUserName = null
      }
    },
    searchName () {
      admin.searchName(this.searchByNameCriteria).then(res => {
        this.users = res.data.data
      })
    },
    searchRole () {
      admin.searchRole(this.searchByRoleCriteria).then(res => {
        this.users = res.data.data
      })
    },
    sortByName () {
      admin.sortByName().then(res => {
        this.users = res.data.data
      })
    },
    sortByRole () {
      admin.sortByRole().then(res => {
        this.users = res.data.data
      })
    }
  }

}
</script>
<style>
.table {
  width: 100%;
}
</style>
